import React, {Component} from "react";
import "./Division.scss";
export default class Division extends Component {
    render(){
        return (
            <article className ="mainmenu">
          <ul className="nav-links1">
          <div className= "mainmenu1" id="block2">
        <li><br/>
        <span className="home-btn">
                    <i className="fas fa-sync fa-2x" /> <br />
        </span>
            <a href= "/" className="nav-link"> processing time </a>
            
        </li>
        </div>
        <div className= "mainmenu1" id="block2">
        <li><br/>
        <span className="home-btn">
        <i className="fas fa-network-wired fa-2x" /> <br />
        </span>
            <a href= "/" className="nav-link"> Events in Queue </a>
        </li>
        </div>
        <div className= "mainmenu1" id="block2">
        <li><br/>
        <span className="home-btn">
        <i className="fas fa-server fa-2x" /> <br />
        </span>
            <a href= "/" className="nav-link"> Active Servers </a>
        </li>
        </div>
        <div className= "mainmenu1" id="block2">
     <li><br/>
        <span className="home-btn">
        <i className="fas fa-check-circle fa-2x" /> <br /> </span>
            <a href= "/" className="nav-link">
                District Status </a>
        </li>
        </div>
        <div className= "mainmenu1" id="block2">
        <li><br/>
        <span className="home-btn">
        <i className="fas fa-globe-europe fa-2x" /> <br /></span>
            <a href= "/" className="nav-link">
                Add District </a>
        </li>
        </div>
        <div className= "mainmenu1" id="block2">
     <li><br/>
        <span className="home-btn">
        <i className="fas fa-users fa-2x" /> <br /> </span>
            <a href= "/" className="nav-link">
                Locate User </a>
        </li>
</div>

    </ul>
          </article>
        )
    }
}
