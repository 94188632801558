import React from "react";
import "./Navbar.scss";
import logo from "../../logo101.png";
import NavDropdown from 'react-bootstrap/NavDropdown'

export default function Navbar() {
return (
<nav className="navbar">
    <img src={logo} alt="Block of Codes" />


    <ul className="nav-links">
        <button class="button button5"><li>
        <a href= "/" className="nav-link"> CHANGE DISTRICT </a>
        </li></button>


        <button class="button button5"><li> 
<a href= "/" className="nav-link"> SYNC SERVER</a>
        </li></button>

        <button class="button button5"><li>
        <a href= "/" className="nav-link"> HELP</a>
        </li></button>
        <button class="button button5"><li>
        <NavDropdown title="MORE" id="basic-nav-dropdown">
        <NavDropdown.Item href="#Login Report">Login Report</NavDropdown.Item>
        <NavDropdown.Item href="#Performance">Performance    </NavDropdown.Item>
        <NavDropdown.Item href="#Import/Export">Import/Export</NavDropdown.Item>
        <NavDropdown.Item href="#Reports">Reports</NavDropdown.Item>
        <NavDropdown.Item href="#Batch Messaging">Batch Messaging</NavDropdown.Item>
      </NavDropdown>
      </li></button>
        
        <li>
            <a href= "/" className="nav-link">
                Welcome Guest !!
                </a>
        </li>
    </ul>
</nav>
);
}
