import React, { Component } from 'react';
import '../../../node_modules/bootstrap/dist/css/bootstrap.min.css';
import BootstrapTable from '../../../node_modules/react-bootstrap-table-next';
export default class Tablee extends Component {
  state = {
    products: [
      {
        id: 23742,
        name: 'Los Angeles School District',
        place: 'California',
        method: 'Classlink',
        SSO: 'Classlink',
        tech: 'Gary Louie',
         'Open Case': 3, 
        
      },
      {
        id: 23345,
        name: 'San Diego School District',
        'Open Case': 2, 
        place: 'California',
        method: 'Manual',
        SSO: 'Classlink',
        tech: 'Mary Campbell',
      },
      {
        id: 54345,
        name: 'San Marcos School District',
        'Open Case': 5, place: 'California',
        method: 'Clever',
        SSO: 'Clever',
        tech: 'Enore Rodi',
      },
      {
        id: 76345,
        name: 'San Jose School District',
        'Open Case': 2, 
        place: 'Texas',
        method: 'Classlink',
        SSO: 'Classlink',
        tech: 'Base Tidd',
      },
      {
        id: 24045,
        name: 'California School District',
        'Open Case': 8, 
        place: 'California',
        method: 'Manual',
        SSO: 'Manual',
        tech: 'Kon Consile',
      },
      {
        id: 23495,
        name: 'New Mexico School District',
        'Open Case': 3, 
        place: 'New Mexico',
        method: 'LTI',
        SSO: 'LTI',
        tech: 'Peter Desa',
      },
      {
        id: 72345,
        name: 'Graded School District',
        'Open Case': 1,   place: 'Arizona',
        method: 'Clever',
        SSO: 'Google',
        tech: 'Mark Twain',
      },
      {
        id: 49345,
        name: 'Balmaki School District',
        'Open Case': 0, 
        place: 'Maryland',
        method: 'Manual',
        SSO: 'Google',
        tech: 'Brad Gomez',
      },
      {
        id: 22345,
        name: 'New Lord USD',
        'Open Case': 3, place: 'New York',   
        method: 'Manual',
        SSO: 'Google',     
        tech: 'Dean Toff',

      },
      {
        id: 20345,
        name: 'New City School District',
        'Open Case': 1, place: 'California',
        method: 'Clever',
        SSO: 'Google', 
        tech: 'Paru Lame',
      },
      {
        id: 22245,
        name: 'Peer Public School',
        'Open Case': 9, place: 'Chicago',
        method: 'SFTP',
        SSO: 'Google',
        tech: 'Nicole Henry', 
      },
      
      {
        id: 34345,
        name: 'Mavni Unified SD',
        'Open Case': 2, place: 'Florida',
        method: 'Manual',
        SSO: 'Google', 
        tech: 'Kar lamp',
      },
      {
        id: 34345,
        name: 'GEMSS Unified SD',
        'Open Case': 2, place: 'Kanas',
        method: 'Manual',
        SSO: 'Google', 
        tech: 'Tina Sah',
      },
      {
        id: 34345,
        name: 'Louisville Unified SD',
        'Open Case': 2, place: 'Kentucky',
        method: 'Manual',
        SSO: 'Manual', 
        tech: 'Manny Pick',
      },
      {
        id: 41324,
        name: 'New Heaven School District ',
        place: 'Los Angeles',
        method: 'Clever',
        SSO: 'Clever',
        tech: 'Mat Leen',
        'Open Case': 10,
      },
    ],
    columns: [{
      dataField: 'id',
      text: 'School ID'
    
    },
    {
      dataField: 'name',
      text: 'School Name'
    }, 
    {
        dataField: 'place',
        text: 'State'
      }, {
        
        dataField: 'method',
        text: 'Onboarding Method'
          }, {
            
        dataField: 'SSO',
         text: 'SSO Method'
        }, {
          dataField: 'tech',
         text: 'Tech Person'
        }, {
        
      dataField: 'Open Case',
      text: 'Open Case',
      sort: true
    }]
  } 
  
  render() {
    return (
      <div className="container" style={{ marginTop: 25, width: 'auto' ,borderColor: "black",borderWidth: 1.5 }}>
        <BootstrapTable 
        striped
        hover
        keyField='id' 
        data={ this.state.products } 
        columns={ this.state.columns } />
      </div>
    );
  }
}
