import React, { Component } from "react";
import Navbar from "./Components/Navbar/Navbar";
import "./App.scss";
import Division from "./Components/Division/Division";
import Divisionlist from "./Components/Divisionlist/Divisionlist";
import Tablee from "./Components/Table/Tablee";
//import Modalbuild from "./Components/Modalbuild/Modalbuild";
//import Router from "./Components/Modal/Router";


class App extends Component {
  render() {
    return (
      <main>
        <Navbar />
        <Division /><br />
        <Divisionlist /> 
      
        <Tablee />

        
       
      
      </main>
    ); 
  }
}

export default App;
