import React, {Component} from "react";
import "./Divisionlist.scss";
export default class Divisionlist extends Component {
    render(){
        return (
            <div id="block3">
                <center>
                <input 
                type="text" 
                placeholder="    Search District Name . . . . " 
                style={{height: 35,width: "90%",borderColor: "gray",borderWidth: 1.5, fontSize: "16px", align: 'center',}}
                required 
              />
              </center>
              </div>
              
    
            
        )
    }
}
